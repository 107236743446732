import React, { useState, useEffect, useRef, Fragment } from 'react';
import axios from 'axios';
const get = axios.get;
import { xhrConfig } from 'form_components/apis/api';

const fetchCurrentUser = async () => {
  return get('/api/current_user.json', {}, xhrConfig());
};

const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        const result = await fetchCurrentUser();
        setCurrentUser(result.data);
        setIsLoading(false);
      } catch (error) {
        setServerError(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  return { isLoading, currentUser, serverError };
};

export default useCurrentUser;
