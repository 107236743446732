import React from 'react'
import PropTypes from 'prop-types'
import FieldLabel from 'form_components/fields/field_label'

const ReadOnlyTextField = ({
  field, // { name, value, onChange, onBlur }
  form, //: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  sublabel,
  required,
  fieldSizeClass,
  append,
  ...props
}) => {
  return (
    <section className='mb-5'>
      <FieldLabel name={field.name} label={label} required={required} sublabel={sublabel} />
      <input ref={props.innerRef || null} disabled className={'rounded-lg p-3 ' + (fieldSizeClass ? fieldSizeClass : 'w-full') + ' border'} type="text" {...field} {...props} />
      {append}
    </section>
  )
}

ReadOnlyTextField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool
}

export default ReadOnlyTextField
