const getCsrfToken = () => {
  const token_el = document.querySelector('meta[name="csrf-token"]');
  if (token_el) {
    return token_el.getAttribute('content');
  } else {
    return 'n/a';
  }
};

export const xhrConfig = () => ({
  headers: { 'X-CSRF-Token': getCsrfToken() },
});

export const postForm = (action, fields) => {
  let form = document.createElement('form');
  form.method = 'POST';
  form.action = action;
  document.body.appendChild(form);
  for (const [name, value] of Object.entries({
    ...fields,
    ...{ authenticity_token: getCsrfToken() },
  })) {
    let field = document.createElement('input');
    field.type = 'hidden';
    field.value = value;
    field.name = name;
    form.appendChild(field);
  }
  form.submit();
};
