import React, { Fragment } from 'react';
import useCurrentUser from '../shared/hooks/use_current_user';
import LoggedInMenu from './logged_in_menu';

const EmbeddedMenu = () => {
  const { _isLoading, currentUser, _serverError } = useCurrentUser();

  return currentUser.id ? (
    <LoggedInMenu currentUser={currentUser} />
  ) : (
    <Fragment></Fragment>
  );
};

export default EmbeddedMenu;
