import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import FieldErrors from 'form_components/fields/field_errors';
import FieldLabel from 'form_components/fields/field_label';

const Tip = ({ contents, visible, toggleVisibility }) => {
  return (
    <div className="tipContainer">
      <p>
        <span
          className="tips-label cursor-pointer"
          data-action={visible ? 'hide' : 'show'}
          onMouseDown={toggleVisibility}
        >
          {visible ? 'Hide' : 'Helpful'} Tips
        </span>
      </p>
      {visible && (
        <div
          className="tipContents mt-2"
          onMouseDown={(e) => {
            e.preventDefault();
          }}
        >
          {contents}
        </div>
      )}
    </div>
  );
};

Tip.propTypes = {
  contents: PropTypes.object,
  visible: PropTypes.bool,
  toggleVisibility: PropTypes.func,
};

const TextareaField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  sublabel,
  required,
  tipContents,
  ...props
}) => {
  const minimumRows = props.minrows || 2;
  const [rows, setRows] = useState(minimumRows);
  const hasError = touched[field.name] && errors[field.name];
  const [tipVisible, setTipVisible] = useState(false);

  const toggleVisibility = () => setTipVisible((prevState) => !prevState);
  const inputRef = useRef(props.innerRef || null);

  return (
    <section className="mb-5">
      {label && label.length > 0 && (
        <FieldLabel
          name={field.name}
          label={label}
          required={required}
          sublabel={sublabel}
        />
      )}
      <div
        className={
          'textAreaWithTip rounded-lg w-full ' +
          (hasError ? ' border-red border-2' : ' border')
        }
      >
        <textarea
          ref={inputRef}
          className="border-none w-full p-3 rounded-lg resize-none"
          rows={rows}
          {...field}
          {...props}
          onFocus={(e) => {
            setTipVisible(true);
          }}
          onBlur={(e) => {
            setTipVisible(false);
          }}
          onChange={(e) => {
            const lineHeight = 18.4;
            const oldRows = e.target.rows;
            e.target.rows = minimumRows;
            const newRows = ~~(e.target.scrollHeight / lineHeight) - 1;
            if (newRows === oldRows) {
              e.target.rows = newRows;
            }
            setFieldValue(field.name, e.target.value);
            setRows(Math.max(minimumRows, newRows));
          }}
        />
        {tipContents && (
          <Tip
            contents={tipContents}
            visible={tipVisible}
            toggleVisibility={toggleVisibility}
          />
        )}
      </div>
      {hasError && <FieldErrors errors={errors[field.name]} />}
    </section>
  );
};

TextareaField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default TextareaField;
