import React from 'react';
import PropTypes from 'prop-types';
import FieldErrors from 'form_components/fields/field_errors';
import FieldLabel from 'form_components/fields/field_label';

const TextField = ({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  sublabel,
  required,
  fieldSizeClass,
  fieldAppendLabel,
  ...props
}) => {
  const hasError = errors[field.name];
  const labelClass =
    'text-sm text-grey-dark mt-2 italic ' +
    (props.fieldAppendLabelSide ? 'inline-block' : 'block');
  return (
    <section className="mb-5">
      {label && label.length > 0 && (
        <FieldLabel
          name={field.name}
          label={label}
          required={required}
          sublabel={sublabel}
        />
      )}
      <input
        ref={props.innerRef || null}
        className={
          'rounded-lg p-3 ' +
          (fieldSizeClass ? fieldSizeClass : 'w-full') +
          (hasError ? ' border-red border-2' : ' border')
        }
        type="text"
        {...field}
        {...props}
      />
      {fieldAppendLabel ? (
        <div className={labelClass}>{fieldAppendLabel}</div>
      ) : (
        ''
      )}
      {hasError && <FieldErrors errors={errors[field.name]} />}
    </section>
  );
};

TextField.propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.string,
  required: PropTypes.bool,
};

export default TextField;
