import React from 'react'
import PropTypes from 'prop-types'

const FieldErrors = ({ errors }) => {
  let errorMessage = Array.isArray(errors) ? errors[0] : errors
  errorMessage = typeof errorMessage === 'string' ? errorMessage : 'unknown error'
  return (
    <div className='text-red capitalizeFirst mt-2 text-sm'>{errorMessage}</div>
  )
}

FieldErrors.propTypes = {
  errors: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
}

export default FieldErrors
