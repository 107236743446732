import React from 'react';
import PropTypes from 'prop-types';

const FieldLabel = ({ name, label, sublabel, required }) => {
  let sublabelItems = [];
  if (sublabel) {
    sublabelItems.push(sublabel);
  }
  const sublabelText =
    sublabelItems.length > 0 ? ` (${sublabelItems.join('; ')})` : '';
  return (
    <label
      className="block font-bold mb-1 text-grey-dark text-sm"
      htmlFor={name}
    >
      {label}
      <span className="font-normal">
        {sublabelText} {required && ' *'}
      </span>
    </label>
  );
};

FieldLabel.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  sublabel: PropTypes.string,
  required: PropTypes.bool,
};

export default FieldLabel;
