import React, { useState, useEffect, useRef, Fragment } from 'react';
import useCurrentUser from '../shared/hooks/use_current_user';
import LogoTeal from '../images/logo-teal.svg';
import LoggedInMenu from './logged_in_menu';

const DesktopDropdownMenu = () => {
  const desktopMenuRef = React.createRef();
  const [expanded, setExpanded] = useState(false);
  const { _isLoading, currentUser, _serverError } = useCurrentUser();
  const wrapperRef = useRef(null);
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setExpanded(false);
      }
    }

    // Bind the event listener
    if (expanded) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef, expanded]);

  const toggleExpand = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <span ref={wrapperRef}>
      <ul className="hidden xl:block list-reset float-left text-lg mt-6">
        <li className="float-left">
          {currentUser.id ? (
            <a
              ref={desktopMenuRef}
              className={
                'userMenuTop ' + (expanded ? 'userMenuTop-expanded' : '')
              }
              onClick={toggleExpand}
            >
              {currentUser.first_name}
            </a>
          ) : (
            <a href="/signin" className="signinJoinLink">
              Join / Sign In
            </a>
          )}
        </li>
      </ul>
      {expanded && (
        <div className="accountMenuContainer">
          {currentUser.id ? (
            <LoggedInMenu currentUser={currentUser} />
          ) : (
            <Fragment></Fragment>
          )}
        </div>
      )}
    </span>
  );
};

export default DesktopDropdownMenu;
