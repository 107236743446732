import moment from 'moment-timezone';
moment.tz.setDefault('America/New_York');

export const httpify = (url) => {
  if (
    url.startsWith('http://') ||
    url.startsWith('https://') ||
    url.startsWith('//')
  ) {
    return url;
  }
  return '//' + url;
};

export const sortWithOrderedArray = (orderedValues, a, b) => {
  if (orderedValues.indexOf(a) > orderedValues.indexOf(b)) {
    return 1;
  }
  if (orderedValues.indexOf(a) < orderedValues.indexOf(b)) {
    return -1;
  }
  return 0;
};

export const filterOptions = (values, sortMethod, labelMap = null) => {
  const counts = (arr) => {
    return arr.reduce(
      (prev, curr) => ((prev[curr] = ++prev[curr] || 1), prev),
      {},
    );
  };
  const valueCounts = counts(values);
  const sortedList =
    labelMap == null
      ? Object.keys(valueCounts).sort(sortMethod)
      : Object.keys(labelMap).sort(sortMethod);
  return sortedList.map((value, index, array) => ({
    value: value,
    label: labelMap == null ? value : labelMap[value],
    count: valueCounts[value] || 0,
  }));
};

export const simpleFormat = (str) => {
  let fstr = str;
  fstr = fstr.replace(/\r\n?/g, '\n');
  fstr = fstr.replace(/\n\n+/g, '</p>\n\n<p>');
  fstr = fstr.replace(/([^\n]\n)(?=[^\n])/g, '$1<br/>');
  fstr = `<p>${fstr}</p>`;
  return fstr;
};

export const truncate = (url, max) =>
  url.length > max ? url.substr(0, max - 1) + '…' : url;

export const highlight = (str, words) => {
  const re = new RegExp(words.join('|'), 'gi');
  return str.replace(re, "<span class='highlighted'>$&</span>");
};

export const formatDate = (d) =>
  moment(d).isValid() ? moment(d).format('MMM D, YYYY') : '';

export const formatDateTime = (d) =>
  moment(d).isValid() ? moment(d).format('MMM D, YYYY h:mma') : '';

export const formatDateCompact = (d) =>
  moment(d).isValid() ? moment(d).format('M/D/YY') : '';

export const centsToDollars = (cents) => {
  const dollars = Math.floor(parseInt(cents) / 100);
  const newCents = cents - dollars * 100;
  return [dollars, ('0' + newCents).slice(-2)].join('.');
};

export const centsToWholeDollars = (cents) => {
  return Math.round(parseInt(cents) / 100);
};

export const dollarsToCents = (dollars) => {
  return Math.floor(parseFloat(dollars.replace(/[^0-9.]/g, '')) * 100);
};

export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const trimSentence = (string, maxChars) => {
  if ((string || '').length <= maxChars) {
    return string;
  }
  let trimmed = (string || '').substr(0, maxChars);
  trimmed = trimmed.substr(
    0,
    Math.min(trimmed.length, trimmed.lastIndexOf(' ')),
  );
  return trimmed + '…';
};

export const templatize = (template, tokens) => {
  return template
    .replace(/\%\{name\}/gm, tokens.name)
    .replace(/\%\{current_newsletter_date\}/gm, tokens.upcomingNewsletterDate);
};

export const customModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  content: {
    top: '70px',
    left: 'auto',
    right: '5px',
    bottom: '5px',
    minWidth: '30%',
    maxWidth: '800px',
  },
};
