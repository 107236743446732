import React from 'react';

const CustomCheckbox = ({ field, form, label, handleChange, ...props }) => (
  <div className="mb-4 text-base leading-normal">
    <div className="customCheckbox">
      <input
        type="checkbox"
        name={'b-' + field.name}
        id={'b-' + field.name}
        {...props}
        checked={field.value === true}
        onChange={
          handleChange
            ? (e) => handleChange(e, form, field)
            : (e) => form.setFieldValue(field.name, e.currentTarget.checked)
        }
      />
      <label htmlFor={'b-' + field.name}>{label}</label>
    </div>
  </div>
);
export default CustomCheckbox;
