import React from 'react'
import PropTypes from 'prop-types'

const Previous = ({ handlePreviousClick, labelText }) => (
  <div className='form-fields nav'>
    <button type='button' className='btn btn-grey' onMouseDown={handlePreviousClick} onClick={handlePreviousClick}>
      {labelText || '< Previous'}
    </button>
  </div>
)

const { func, string } = PropTypes

Previous.propTypes = {
  handlePreviousClick: func.isRequired,
  labelText: string
}

export default Previous
